<template>
  <div class="home">
    <au-goodness2></au-goodness2>
  </div>
</template>

<script>
import auClerk from "@/components/auClerk";
import auGoodness from "@/components/auGoodness";
import auGoodness2 from "@/components/auGoodness2";
import auFooter from "@/components/auFooter";

export default {
  name: 'GongNeng',
  components: {
    auClerk,auGoodness,auGoodness2,auFooter
  }
}
</script>
