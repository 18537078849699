<template>
  <div>
    <au-clerk :is-download="true" @download="download"></au-clerk>
    <div class="pageHome">
      <div class="c">
        <div class="page2">
          <img style="width: 120px" src="../assets/code.png" alt="code">
          <div class="down" @click="download(config.appLink)">
            <img src="../assets/app.png" alt="app">
            <div>App下载安装</div>
          </div>
          <div class="down" @click="download(config.exeLink)">
            <img src="../assets/exe.png" alt="exe">
            <div>电脑软件下载</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auClerk from "@/components/auClerk";

export default {
  name: "Download",
  components: {
    auClerk
  },
  methods:{
    download(url){
      window.open(url);
    }
  }
}
</script>

<style scoped lang="scss">
h2{
  text-align: center;
  width: 100%;
}
.pageHome{
  background: #f1f1f1;
}
.page2{
  display: flex;
  justify-content: space-between;
  padding: 30px;
  flex-wrap: wrap;
}
.down{
  box-shadow: 1px 3px 5px 0px rgba(227, 227, 227, 0.17);
  transform: translateY(0px);
  transition: transform .3s;
  background: #ffffff;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
  width: 150px;
  height: 180px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  color: #868686;
  flex: 1;
  margin: 0 30px;
  div{
    margin-top: 15px;
  }
  &:hover{
    box-shadow: 1px 3px 5px 0px rgba(206, 205, 205, 0.17);
    transform: translateY(-10px);
  }
}
.browser-icon {
  display: inline-block;
  background: url('https://qiniu.amingtool.com/gw/icon/download/all-browser-icon.png') no-repeat center;
  width: 32px;
  height: 32px;
}

.browser-icon1 {
  background-position: 0 0;
}

.browser-icon2 {
  background-position: -56px -96px;
}

.browser-icon3 {
  background-position: -56px -142px;
}

.browser-icon4 {
  background-position: 0 -142px;
}

.browser-icon5 {
  background-position: -114px 0;
}

.browser-icon6 {
  background-position: -56px 0;
}

.browser-icon7 {
  background-position: -56px -45px;
}

.browser-icon8 {
  background-position: 0 -46px;
}

.browser-icon9 {
  background-position: 0 -96px;
}

.browser-icon10 {
  background-position: -114px -46px;
}

</style>
