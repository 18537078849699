<template>
  <div class="pageHome">
    <div class="c">
      <div class="head">App使用教程</div>
      <div class="cvt">
        <h3>1.先在官网下载app并安装到手机</h3>
        <img src="../assets/p4.jpg" alt="">
      </div>
      <div class="cvt">
        <h3>2.在应用详情页给予app所有权限</h3>
        <img src="../assets/p2.jpg" alt="">
      </div>
      <div class="cvt">
        <h3>3.设置允许自启动和后台运行</h3>
        <img src="../assets/p1.jpg" alt="">
        <img src="../assets/p3.jpg" alt="">
      </div>
      <div class="cvt">
        <h3>4.登录账户即可</h3>
        <img src="../assets/p5.jpg" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Serve"
}
</script>

<style scoped lang="scss">
.head{
  text-align: center;
  padding: 30px;
  font-size: 24px;
  border-bottom: 1px solid #f1f1f1;
  color: #4e4e4e;
}
.cvt{
  padding: 30px 0;
  color: #797979;
  h3{
    position: relative;
    font-weight: 400;
    &::after{
      position: absolute;
      display: block;
      content: '';
      left: 0;
      bottom: -10px;
      height: 2px;
      background: #c9c9c9;
      width: 30px;
    }
  }
  img{
    width: 240px;
    margin-right: 15px;
  }
}
</style>
